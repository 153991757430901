/** @format */

import { LOGIN, LOGOUT } from "./actionTypes";

export const initialState = {
  name: null,
  email: null,
  phone: null,
  token: null,
  isAdmin: null,
  isSales: null,
  permission_list: null,
  access_identifier: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export default reducer;
