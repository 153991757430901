import { Typography } from "antd";
import React from "react";
import error_icon from "../../Assets/subscription_pilot/error.png";

const style = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    maxWidth: "500px",
    width: "100%",
    margin: "auto",
  },
  icon: {
    fontSize: "10rem",
    marginBottom: "16px",
  },
  btn: {
    marginTop: "16px",
  },
};

export default function ErrorView() {
  return (
    <div style={style.root}>
      <img src={error_icon} alt="Icon not found" />
      <Typography variant="h4" component="h2" gutterBottom>
        Oops! Something went wrong
      </Typography>
      <p>We're sorry, but an error occurred while loading this component.</p>
      <p>Please try again later or contact support for assistance.</p>
    </div>
  );
}
