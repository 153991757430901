/** @format */

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ContextFunctionality from "./Pages/Replenishment/ContextApi/ContextFunctionality";
import ErrorBoundary from "./Components/errorBoundries/ErrorBoundary";
import ErrorView from "./Components/errorBoundries/ErrorView";

ReactDOM.render(
  <>
    <ContextFunctionality>
      <ErrorBoundary fallback={<ErrorView />}>
        <App />
      </ErrorBoundary>
    </ContextFunctionality>
  </>,
  document.getElementById("root")
);

reportWebVitals();
