/** @format */

import React, { lazy, Suspense } from "react";
import "antd/dist/antd.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { persistor, store } from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";

import FallbackLoader from "../Components/Loading/FallbackLoader";
import Layout from "../layouts/Layout";
import {
  ShouldBeLoggedIn,
  ShouldBeLoggedOut,
  // MustBeAdminAndNormalUser,
  MustHavePermission,
} from "./ProtectRoutes";
import { routes } from "./Routes";
import ErrorPage from "../Components/NotFound/ErrorPage";
import { newBaseUrl } from "../apis/newBaseUrl/url";

// This method will be used in routes so that the files are loaded only when users are on that route
const Login = lazy(() => import("../Pages/auth/login"));

axios.defaults.baseURL = newBaseUrl;
axios.defaults.responseType = "json";
axios.defaults.headers["Content-Type"] = "application/json";

const theme = {
  colors: {
    primary: "#ca1134",
    secondary: "#4C5C68",
    border: "#282a2b",
    border2: "#C5C3C6",
    background: "#DCDCDD",
    textColor: "white",
  },
};

function AppRouters() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<FallbackLoader />}>
            <Router>
              <Layout>
                <Routes>
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={
                        <ShouldBeLoggedIn>
                          <MustHavePermission>
                            {route.element}
                          </MustHavePermission>
                        </ShouldBeLoggedIn>
                      }
                    />
                  ))}

                  <Route
                    path="/"
                    element={
                      <ShouldBeLoggedOut>
                        <Login />
                      </ShouldBeLoggedOut>
                    }
                  />

                  <Route
                    path="*"
                    element={
                      <ShouldBeLoggedIn>
                        <ErrorPage />
                      </ShouldBeLoggedIn>
                    }
                  />
                </Routes>
              </Layout>
            </Router>
          </Suspense>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default AppRouters;
