import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { routes } from "./Routes";

export const ShouldBeLoggedIn = ({ children }) => {
  const location = useLocation();
  const { token } = useSelector((state) => state?.auth);

  if (token) {
    return children;
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};

export const ShouldBeLoggedOut = ({ children }) => {
  const location = useLocation();
  const token = useSelector((state) => state?.auth.token);
  const { permission_list } = useSelector((state) => state?.auth);

  const defaultPath = permission_list?.find(
    (route, index) => route === "dashboard"
  );

  if (!token) {
    return children;
  } else {
    return (
      <Navigate
        to={`/${
          defaultPath?.length ? defaultPath : permission_list[0].split(".")[0]
        }`}
        state={{ from: location }}
        replace
      />
    );
  }
};

export const MustBeAdmin = ({ children }) => {
  const isAdmin = useSelector((state) => state?.auth?.isAdmin) === true;
  const location = useLocation();
  if (!isAdmin) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

export const MustHavePermission = ({ children }) => {
  const { permission_list } = useSelector((state) => state?.auth);

  const location = useLocation();
  // const permission_list = ["customer-journey.rsm.all"];

  if (permission_list.length === 0) {
    return <Navigate to="/no-access" replace />;
  }

  const currentRoute = routes.find((route) => route.path === location.pathname);

  const currentAccessLevel = permission_list.find((AccessLevel) => {
    const splitAccessLevel = AccessLevel?.split(".");
    if (splitAccessLevel[0] === currentRoute.permission) {
      return AccessLevel;
    }
  });

  if (!currentRoute) {
    return <Navigate to="/no-access" replace />;
  }

  if (currentAccessLevel && permission_list.includes(currentAccessLevel)) {
    return children;
  } else {
    return <Navigate to="/no-access" replace />;
  }
};

// export const MustBeAdmin = ({ children }) => {
//   const isAdmin = useSelector((state) => state?.auth?.isAdmin) === true;
//   const location = useLocation();
//   if (!isAdmin) {
//     return <Navigate to="/" state={{ from: location }} replace />;
//   }
//   return children;
// };

// export const MustBeAdminAndNormalUser = ({ children }) => {
//   const { isAdmin, isSales } = useSelector((state) => state?.auth);
//   const location = useLocation();
//   if (isAdmin) {
//     return children;
//   }
//   if (!isAdmin && isSales) {
//     return (
//       <Navigate to="/customer-journey" state={{ from: location }} replace />
//     );
//   }
//   return children;
// };
