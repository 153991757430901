/** @format */

import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flexbox;
  width: 100%;
`;

// export const Sider = styled.div`
//   position: fixed;
//   height: 100vh;
//   width: 48px;
//   background-color: ${(props) => props.theme.colors.border};
//   overflow: hidden;
//   z-index: 999;
//   transition: 0.8s;
//   overflow-x: hidden;
//   @media (max-width: 768px) {
//     display: none;
//   }

//   &:hover {
//     width: 200px;
//   }
// `;

// export const SiderOption = styled.div`
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   aspect-ratio: 1;
//   background-color: ${(props) =>
//     props.selected ? "rgba(114,104,121,.3)" : "transparent"};
//   border-top: 1px solid #39313d;
//   border-left: ${(props) =>
//     props.selected
//       ? `2px solid ${props.theme.colors.primary}`
//       : "2px solid transparent"};
//   align-items: center;
//   cursor: pointer;
//   margin: 0;
//   height: 68px;
// `;

export const BodyContainer = styled.div`
  width: 100%;
  /* width: calc(100% - 48px);
  position: relative;
  left: 48px;
  @media (max-width: 768px) {
    width: 100%;
    left: 0;
  } */
`;
