export const PowerBiReports = [
  {
    name: "Cash flow",
    url: "https://app.powerbi.com/reportEmbed?reportId=58c32dee-21d1-4c4b-bfed-1711851135d2&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944",
  },
  {
    name: "Fullfillment status",
    url: "https://app.powerbi.com/reportEmbed?reportId=77918320-69c9-46f3-972f-339de86625a1&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "Order profit report",
    url: "https://app.powerbi.com/reportEmbed?reportId=2867a6d4-08d9-4b91-8c6c-80c9936d9b01&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "Product closing qty",
    url: "https://app.powerbi.com/reportEmbed?reportId=7a7ed093-76d1-48f1-9998-f485764defa5&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "Product wise overview",
    url: "https://app.powerbi.com/reportEmbed?reportId=d66ff572-da09-4d4a-8f80-381a329204e3&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "Purchase history",
    url: "https://app.powerbi.com/reportEmbed?reportId=f85d8ead-cba3-4726-800d-372e3b234e58&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "Sale & purchase",
    url: "https://app.powerbi.com/reportEmbed?reportId=e5576d45-150d-4f34-a04d-4965fd7ab203&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "Sale comparison",
    url: "https://app.powerbi.com/reportEmbed?reportId=d87c818d-67b0-42c3-932d-75089ce5e03a&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "Sale performance report",
    url: "https://app.powerbi.com/reportEmbed?reportId=91651dda-e503-435c-a452-c96c9d0ac0db&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "SKU penetration",
    url: "https://app.powerbi.com/reportEmbed?reportId=19818b31-b4ba-4468-b13b-ec4914d8c83e&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "SKU purchase",
    url: "https://app.powerbi.com/reportEmbed?reportId=ace0e674-e344-4636-a1af-a82aeb4b9bb7&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "Weekly growth",
    url: "https://app.powerbi.com/reportEmbed?reportId=cfaf14c3-85f7-4242-974d-367c663b19d0&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "Customer status and gmv trend",
    url: "https://app.powerbi.com/reportEmbed?reportId=647c343b-5581-4547-a1bd-74c6e0c8d25d&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "Demand and current stock",
    url: "https://app.powerbi.com/reportEmbed?reportId=7d6dc5c2-6a7b-4c28-8134-24f924f51b7f&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },
  {
    name: "Stock availablity report",
    url: "https://app.powerbi.com/reportEmbed?reportId=d5b45d15-38d3-4df9-a728-bd55e8ddb4dc&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944%22",
  },

  {
    name: "Current and demand sku",
    url: "https://app.powerbi.com/reportEmbed?reportId=99adc75f-9f71-442e-ba8e-7f18f6bc1416&autoAuth=true&ctid=fe7f8c53-1837-469c-b4d5-e2aa3b9a4944",
  },
];
