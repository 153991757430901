/** @format */

import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { BodyContainer, HeaderContainer } from "./styles";
import { Link } from "react-router-dom";

// import Home from "../../Assets/Home.svg";
import Menu from "../../Assets/menu.svg";
import arrow from "../../Assets/caravan_arrow.png";
import Header from "../Header";
import { ContextProvide } from "../../Pages/Replenishment/ContextApi/ContextFunctionality";
import "./sidebar.css";
import { PowerBiReports } from "../../routers/reports/PowerBiReports";
import { CustomReports } from "../../routers/reports/CustomReports";
import { routes } from "../../routers/Routes";
import { useSelector } from "react-redux";

const MainContainer = ({ title, children, headerComponent }) => {
  const ref = useRef();
  const { permission_list } = useSelector((state) => state?.auth);

  const { reportName, setReportName, setApply, setLoading } =
    useContext(ContextProvide);
  const [toggle, setToggle] = useState(false);
  const [toogleSunMenu, setToogleSubMenu] = useState(false);
  const [toogleUserList, setToogleUserList] = useState(false);
  const [salesToggle, setSalesToggle] = useState(false);

  // detect click outside
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (toggle && ref.current && !ref.current.contains(e.target)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [toggle]);

  // report state set in_local_storage

  const saveValueToLocalStorage = useCallback(() => {
    if (reportName) {
      window.localStorage.setItem("report", JSON.stringify(reportName));
    }
  }, [reportName]);

  useEffect(() => {
    saveValueToLocalStorage();
  }, [saveValueToLocalStorage]);

  const isVisible = (permission) => {
    return (
      !permission ||
      permission_list.some((item) => item.split(".")[0].includes(permission))
    );
  };

  const hasPermission = (permission_list, keyword) => {
    return permission_list.some((item) => item.split(".")[0].includes(keyword));
  };

  const hasFullStringMatch = (permission_list, keyword) => {
    return permission_list.includes(keyword);
  };

  return (
    <HeaderContainer>
      {permission_list?.length ? (
        <img src={Menu} className="menuIcon" onClick={() => setToggle(true)} />
      ) : null}

      {toggle ? (
        <div className="sidebarContainer" ref={ref}>
          <div className="closeBtn" onClick={() => setToggle(false)}>
            X
          </div>
          <div className="sidenav">
            {routes.map((route, index) => {
              const isVisibleRoute = isVisible(route.permission);
              console.log(route.permission);

              if (!route.permission || !isVisibleRoute) {
                return null;
              }

              switch (route.permission) {
                case "stock-summery":
                  return (
                    <Link to="/stock-summery" key={index}>
                      Stock Summary
                    </Link>
                  );
                case "focused-skus":
                  return (
                    <Link to="/focused-skus" key={index}>
                      Focused Skus
                    </Link>
                  );
                case "subscription-dashboard":
                  return (
                    <Link onClick={() => setToggle(false)} to="/subscription-dashboard" key={index}>
                      Subscription
                    </Link>
                  );
                case "summary":
                  return (
                    <Link to="/summary" key={index}>
                      Sku wise summary
                    </Link>
                  );
                case "document":
                  return (
                    <Link to="/document" key={index}>
                      Upload Documents
                    </Link>
                  );
                case "kaa_visit_plan":
                  return (
                    <Link to="/kaa_visit_plan" key={index}>
                      KAA visit plan
                    </Link>
                  );
                case "category-dashboard":
                  return (
                    <Link to="/category-dashboard" key={index}>
                      Category Dashboard
                    </Link>
                  );
                case "subscription-pilot":
                  return (
                    <Link to="/subscription-pilot" key={index}>
                      Subscription Pilot
                    </Link>
                  );
                case "dashboard":
                  if (hasFullStringMatch(permission_list, "dashboard")) {
                    return (
                      <Link onClick={() => setToggle(false)} to="/dashboard" key={index}>
                        Home
                      </Link>
                    );
                  }
                  return null;
                default:
                  return null;
              }
            })}

            {hasPermission(permission_list, "area") ||
            hasPermission(permission_list, "sales-hierarchy") ||
            hasPermission(permission_list, "customer-journey") ? (
              <button
                className="dropdown-btn"
                onClick={() => setSalesToggle(!salesToggle)}
              >
                <label>Customer Journey</label>
                <img src={arrow} className="arrow" />
              </button>
            ) : null}

            {salesToggle ? (
              <div className="dropdown-container">
                {routes.map((route, index) => {
                  const isVisibleRoute = isVisible(route.permission);

                  if (!route.permission || !isVisibleRoute) {
                    return null;
                  }

                  switch (route.permission) {
                    case "customer-journey":
                      return (
                        <Link
                          key={index}
                          to="/customer-journey"
                          onClick={() => {
                            setLoading(true);
                            setApply(true);
                          }}
                        >
                          Customer Journey
                        </Link>
                      );
                    case "sales-hierarchy":
                      return (
                        <Link to="/sales-hierarchy" key={index}>
                          Sales Hierarchy
                        </Link>
                      );
                    case "area":
                      return (
                        <Link to="/area" key={index}>
                          Area
                        </Link>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            ) : null}

            {hasPermission(permission_list, "create-user") ||
            hasPermission(permission_list, "register-user") ? (
              <button
                className="dropdown-btn"
                onClick={() => setToogleUserList(!toogleUserList)}
              >
                <label>Users</label>
                <img src={arrow} className="arrow" />
              </button>
            ) : null}
            {toogleUserList ? (
              <div className="dropdown-container">
                {routes.map((route, index) => {
                  const isVisibleRoute = isVisible(route.permission);

                  if (!route.permission || !isVisibleRoute) {
                    return null;
                  }

                  switch (route.permission) {
                    case "create-user":
                      return (
                        <Link key={index} to="/create-user">
                          Create User
                        </Link>
                      );
                    case "register-user":
                      return (
                        <Link key={index} to="/register-user">
                          Register User
                        </Link>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            ) : null}

            {hasPermission(permission_list, "bi-reports") ||
            hasPermission(permission_list, "customer-segmentation") ||
            hasPermission(permission_list, "product-penetration") ||
            hasPermission(permission_list, "product-overview") ||
            hasPermission(permission_list, "sale-purchase-overview") ||
            hasPermission(permission_list, "sale-purchase") ||
            hasPermission(permission_list, "weekly-report") ||
            hasPermission(permission_list, "product-distribution") ||
            hasPermission(permission_list, "sales-performance") ? (
              <button
                className="dropdown-btn"
                onClick={() => setToogleSubMenu(!toogleSunMenu)}
              >
                <label>Reports</label>
                <img src={arrow} className="arrow" />
              </button>
            ) : null}

            {toogleSunMenu ? (
              <div className="dropdown-container">
                {CustomReports.map((report, index) => {
                  const url = report.url.replace(/^\/|\/$/g, "");

                  const matchingRoute = routes.find(
                    (route) => route.permission === url
                  );
                  if (matchingRoute && isVisible(matchingRoute.permission)) {
                    return (
                      <Link to={`/${matchingRoute.permission}`} key={index}>
                        {report.name}
                      </Link>
                    );
                  }
                  return null;
                })}

                {PowerBiReports?.map((el, index) => {
                  const matchingRoute = routes.find(
                    (route) => route.permission === "bi-reports"
                  );
                  if (matchingRoute && isVisible(matchingRoute.permission)) {
                    return (
                      <Link
                        to="/bi-reports"
                        key={index}
                        onClick={() => setReportName(el)}
                      >
                        {el?.name}
                      </Link>
                    );
                  }
                })}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      <BodyContainer>
        <Header title={title}>{headerComponent}</Header>
        {children}
      </BodyContainer>
    </HeaderContainer>
  );
};

export default React.memo(MainContainer);
