import React from "react";
import loading_icon from "../../Assets/apni-dukaan.png";
import { Loading, Image } from "./style";

export default function FallbackLoader() {
  return (
    <Loading>
      <Image src={loading_icon} alt="loading..." />
    </Loading>
  );
}
