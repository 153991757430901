/** @format */

import {
  LOGIN,
  LOGOUT,
  CM,
  AREAS,
  RSM,
  FILTERS,
  ADD_TO_ASSIGNED_KAA_LIST,
} from "./actionTypes";

export const loginAction = ({
  name,
  email,
  phone,
  token,
  isAdmin,
  isSales,
  permission_list,
  access_identifier,
}) => ({
  type: LOGIN,
  payload: {
    name,
    email,
    phone,
    token,
    isAdmin,
    isSales,
    permission_list,
    access_identifier,
  },
});

export const logoutAction = () => ({
  type: LOGOUT,
});

export const saleAreas = (area) => {
  return {
    type: AREAS,
    payload: area,
  };
};

// export const addToAssignedKaaList = (itemId, newData) => {
//   return {
//     type: ADD_TO_ASSIGNED_KAA_LIST,
//     payload: { itemId, newData },
//   };
// };

export const saleRsm = (rsm) => ({
  type: RSM,
  payload: rsm,
});

export const salesCm = (cm) => ({
  type: CM,
  payload: cm,
});

export const journeyFilters = (filter) => ({
  type: FILTERS,
  payload: filter,
});
