/** @format */

import styled from "styled-components";

// export const HeaderText = styled.div`
//   font-size: 20px;
//   line-height: 24px;
//   font-weight: 500;
//   margin: 0 8px 0 0;
//   padding: 4px 0;
//   vertical-align: middle;
//   display: block;
//   color: #423d46;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// `;

export const HeaderComponent = styled.div`
  height: 68px;
  /* padding: 0 5rem; */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 999;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

export const HeaderLogoLeft = styled.img`
  width: 64px;
  margin-left: 24px;

  @media (max-width: 768px) {
    margin-left: 54px;
  }
`;
// export const HeaderLogoRight = styled.img`
//   height: 42px;
//   position: absolute;
//   right: 3rem;

//   @media (max-width: 768px) {
//     display: none;
//   }
// `;
