import styled, { keyframes } from "styled-components";
const blinkAmination = keyframes`
0% { height: 100px; width: 160px; }
 30% { height: 150px; width: 210px; opacity: 1 }
 40% { height: 180px; width: 240px; opacity: 0.3; }
 100% { height: 200px; width: 260px; opacity: 0.6; }

`;

const rotateAnimation = keyframes`
  100% {
    -moz-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg); 
}
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  
}

`;

export const Loading = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  flex-direction: column;
`;
export const Image = styled.img`
  width: 160px;
  height: 100px;
  animation-name: ${blinkAmination};
  animation-duration: 3s;
  animation-iteration-count: infinite;
`;

export const LoadingImage = styled.img`
  -webkit-animation: ${rotateAnimation} 1s linear infinite;
  -moz-animation: ${rotateAnimation} 1s linear infinite;
  animation: ${rotateAnimation} 3s linear infinite;
`;

export const LoaderLabel = styled.label``;
