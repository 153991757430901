import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../Pages/auth/styles";
import { MianWraper, FourZeroFourBg, PageContent } from "./style";
import { useSelector } from "react-redux";

export default function ErrorPage() {
  const { permission_list } = useSelector((state) => state?.auth);

  const routePath = permission_list[0];

  return (
    <>
      <MianWraper>
        <FourZeroFourBg />

        <PageContent>
          <h3>Look like you're lost</h3>
          <p>The page you are looking for not accessable!</p>

          <Button
            style={{
              maxWidth: "200px",
              borderRadius: "4px",
              margin: "auto",
            }}
          >
            <Link
              to={`/${
                routePath?.length > 0 ? routePath?.split(".")[0] : "404"
              }`}
              style={{ color: "white" }}
            >
              Go Back
            </Link>
          </Button>
        </PageContent>
      </MianWraper>
    </>
  );
}
