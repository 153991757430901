import React from "react";
import { HeaderComponent, HeaderLogoLeft } from "./styles";
import icon from "../../Assets/icon.png";
import { useSelector } from "react-redux";

const Header = ({ title, children }) => {
  const { permission_list } = useSelector((state) => state?.auth);

  return (
    <HeaderComponent
      style={
        permission_list?.length > 0
          ? { padding: "0 5rem" }
          : { padding: "0 4rem 0 1rem " }
      }
    >
      {/* <HeaderText>{title}</HeaderText> */}
      <HeaderLogoLeft src={icon} />
      {children}
      {/* <HeaderLogoRight src={LogoRight} /> */}
    </HeaderComponent>
  );
};

export default Header;
