export const CustomReports = [
  {
    name: "Customer Segmentation",
    url: "/customer-segmentation",
  },
  {
    name: "Product Penetration",
    url: "/product-penetration",
  },
  {
    name: "Product Overview",
    url: "/product-overview",
  },
  {
    name: "Sale Purchase Overview",
    url: "/sale-purchase-overview",
  },
  {
    name: "Sale Purchase",
    url: "/sale-purchase",
  },
  {
    name: "Weekly Report",
    url: "/weekly-report",
  },
  {
    name: "Product Distribution",
    url: "/product-distribution",
  },
  {
    name: "Sales Performance",
    url: "/sales-performance",
  },
];
