import AppRouters from "./routers/AppRouters";

function App() {
  return (
    <div>
      <AppRouters />
    </div>
  );
}

export default App;
