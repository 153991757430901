import { CM } from "./actionTypes";

const initialState = {
  cm: null,
};

const cmReducer = (state = initialState, action) => {
  switch (action.type) {
    case CM:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default cmReducer;
