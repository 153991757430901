import React from "react";
import MainContainer from "../Components/MainContainer";
import HeaderComponent from "../Pages/Replenishment/headerComponent";

export default function Layout({ children }) {
  return (
    <div>
      <MainContainer
        title={"Replenishment"}
        headerComponent={<HeaderComponent />}
      >
        {children}
      </MainContainer>
    </div>
  );
}
