import React, { lazy } from "react";
// get data from redux
const Forget = lazy(() => import("../Pages/auth/forget"));
const Dashboard = lazy(() => import("../Pages/Replenishment/homePage"));
const Documents = lazy(() => import("../Pages/upload_documents/Documents"));
const Summary = lazy(() => import("../Pages/summary/Summary"));
const CreateNewUser = lazy(() => import("../Pages/auth/createUser"));
const PowerBiReports = lazy(() =>
  import("../Pages/Replenishment/reports/bi_reports/PowerBiReports")
);
const RegisteredUser = lazy(() =>
  import("../Pages/registeredUserTable/registeredUser")
);
const StockSummery = lazy(() => import("../Pages/Replenishment/stockSummery"));
const UserProfile = lazy(() => import("../Pages/auth/userProfile"));
const FocusedSkus = lazy(() => import("../Pages/skus/focusedSkus"));
const ManageSkus = lazy(() => import("../Pages/skus/ManageSkus"));
const UploadSkus = lazy(() => import("../Pages/skus/upload_sku"));
const CurrentStockDemand = lazy(() =>
  import("../Pages/skus/currentStockDemand/CurrentStockDemand")
);
const GeoLocation = lazy(() =>
  import("../Pages/Replenishment/reports/customer_segmentation/GeoLocation")
);
const ProductPenetration = lazy(() =>
  import(
    "../Pages/Replenishment/reports/product_penetration/ProductPenetration"
  )
);
const ProductOverview = lazy(() =>
  import(
    "../Pages/Replenishment/reports/productOverview_and_fulfilment/productwise_overview/ProductOverview"
  )
);
const ProductFulfilment = lazy(() =>
  import(
    "../Pages/Replenishment/reports/productOverview_and_fulfilment/productFulfilment/ProductFulfilment"
  )
);
const SaleAndPurchase = lazy(() =>
  import("../Pages/Replenishment/reports/saleAndPurchase/SaleAndPurchase")
);
const WeeklySalesGrowth = lazy(() =>
  import("../Pages/Replenishment/reports/weekly_sales_growth/WeeklySalesGrowth")
);
const ProductDistribution = lazy(() =>
  import(
    "../Pages/Replenishment/reports/product_distribution/ProductDistribution"
  )
);
const SalesPerformance = lazy(() =>
  import("../Pages/Replenishment/reports/sales_performance/SalesPerformance")
);

const CustomerJourney = lazy(() =>
  import("../Pages/customerJourney/CustomerJourney")
);

const SalesArea = lazy(() => import("../Pages/sales/saleAreas/SalesArea"));
const SalesHierarchy = lazy(() =>
  import("../Pages/sales/salesHierarchy/SalesHierarchy")
);

const AreaView = lazy(() =>
  import("../Pages/sales/saleAreas/areaView/AreaView")
);

const KaaVisit = lazy(() =>
  import("../Pages/Replenishment/reports/kaa_visit_plan/KaaVisit")
);

// product dasboard
const ProductDashboard = lazy(() =>
  import("../Pages/productDashboard/ProductDashboard")
);

// subscription-pilot
const SubscriptionPilot = lazy(() =>
  import("../Pages/subscriptionPilot/SubscriptionPilot")
);

export const routes = [
  {
    path: "/user-profile",
    element: <UserProfile />,
    permission: "user-profile",
  },
  {
    path: "/update-password",
    element: <Forget />,
    permission: "update-password",
  },
  {
    path: "/create-user",
    element: <CreateNewUser />,
    permission: "create-user",
  },
  {
    path: "/register-user",
    element: <RegisteredUser />,
    permission: "register-user",
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    permission: "dashboard",
  },
  {
    path: "/focused-skus",
    element: <FocusedSkus />,
    permission: "focused-skus",
  },
  {
    path: "/current-stock",
    element: <CurrentStockDemand />,
    permission: "current-stock",
  },
  {
    path: "/manage-skus",
    element: <ManageSkus />,
    permission: "manage-skus",
  },
  {
    path: "/upload-skus",
    element: <UploadSkus />,
    permission: "upload-skus",
  },
  {
    path: "/stock-summery",
    element: <StockSummery />,
    permission: "stock-summery",
  },
  {
    path: "/document",
    element: <Documents />,
    permission: "document",
  },
  {
    path: "/summary",
    element: <Summary />,
    permission: "summary",
  },
  {
    path: "/bi-reports",
    element: <PowerBiReports />,
    permission: "bi-reports",
  },
  {
    path: "/customer-segmentation",
    element: <GeoLocation />,
    permission: "customer-segmentation",
  },
  {
    path: "/product-penetration",
    element: <ProductPenetration />,
    permission: "product-penetration",
  },
  {
    path: "/product-overview",
    element: <ProductOverview />,
    permission: "product-overview",
  },
  {
    path: "/sale-purchase-overview",
    element: <ProductFulfilment />,
    permission: "sale-purchase-overview",
  },
  {
    path: "/sale-purchase",
    element: <SaleAndPurchase />,
    permission: "sale-purchase",
  },
  {
    path: "/weekly-report",
    element: <WeeklySalesGrowth />,
    permission: "weekly-report",
  },
  {
    path: "/product-distribution",
    element: <ProductDistribution />,
    permission: "product-distribution",
  },
  {
    path: "/sales-performance",
    element: <SalesPerformance />,
    permission: "sales-performance",
  },
  {
    path: "/kaa_visit_plan",
    element: <KaaVisit />,
    permission: "kaa_visit_plan",
  },
  {
    path: "/customer-journey",
    element: <CustomerJourney />,
    permission: "customer-journey",
  },
  {
    path: "/sales-hierarchy",
    element: <SalesHierarchy />,
    permission: "sales-hierarchy",
  },
  {
    path: "/area",
    element: <SalesArea />,
    permission: "area",
  },
  {
    path: "/area/area-view",
    element: <AreaView />,
    permission: "area-view",
  },
  {
    path: "/category-dashboard",
    element: <ProductDashboard />,
    permission: "category-dashboard",
  },
  {
    path: "/subscription-dashboard",
    element: <SubscriptionPilot />,
    permission: "subscription-dashboard",
  },
];
