import { combineReducers } from "redux";
import reducer from "./reducer";
import cmReducer from "./cmReducer";
import areaReducer from "./areaReducer";
import rsmReducer from "./rsmReducer";
import filtersReducer from "./FiltersReducer";

const rootReducer = combineReducers({
  auth: reducer,
  cm: cmReducer,
  area: areaReducer,
  rsm: rsmReducer,
  filter: filtersReducer,
});

export default rootReducer;
