/** @format */

import React, { createContext, useState } from "react";
export const ContextProvide = createContext();

const ContextFunctionality = (props) => {
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState();
  const [chartVisible, setChartVisible] = useState(false);
  const [clickedInformation, setClickedInformation] = useState(false);
  const [tableRowClicked, setTableRowClicked] = useState(false);
  const [monthlyRecShow, setMonthlyRecShow] = useState(false);
  const [catagoriesData, setCatagoriesData] = useState([]);
  const [selectedCatagory, setSelectedCatagory] = useState();
  const [selectSubCategory, setSelectSubCategory] = useState();
  const [apply, setApply] = useState(true);
  const [applyReplenishment, setApplyReplenishment] = useState(true);
  const [applyWeekAnalysis, setapplyWeekAnalysis] = useState(true);

  const [showProductDetails, setShowProductDetails] = useState();
  const [filtersData, setFiltersData] = useState();
  const [penetrationFilterData, setPenetrationFilterData] = useState();
  const [dashboardApi, setDashboardApi] = useState();
  const [stockSummeryApi, setStockSummeryApi] = useState();
  const [loading, setLoading] = useState(true);
  const [segentationCustomerId, setSegentationCustomerId] = useState();
  const [penetrationCustomerId, setPenetrationCustomerId] = useState();

  const [selectedReportData, setSelectedReportData] = useState();

  // state for reports component
  const [reportName, setReportName] = useState("");

  // for same report filters
  const [mainCategory, setMainCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [sku, setSku] = useState();
  const [productName, setProductName] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  return (
    <>
      <ContextProvide.Provider
        value={{
          warehouses,
          setWarehouses,
          selectedWarehouse,
          setSelectedWarehouse,
          chartVisible,
          setChartVisible,

          clickedInformation,
          setClickedInformation,
          tableRowClicked,
          setTableRowClicked,

          showProductDetails,
          setShowProductDetails,

          dashboardApi,
          setDashboardApi,

          stockSummeryApi,
          setStockSummeryApi,

          loading,
          setLoading,

          catagoriesData,
          setCatagoriesData,

          selectedCatagory,
          setSelectedCatagory,

          monthlyRecShow,
          setMonthlyRecShow,

          apply,
          setApply,

          applyReplenishment,
          setApplyReplenishment,

          selectSubCategory,
          setSelectSubCategory,

          applyWeekAnalysis,
          setapplyWeekAnalysis,

          filtersData,
          setFiltersData,

          penetrationFilterData,
          setPenetrationFilterData,

          segentationCustomerId,
          setSegentationCustomerId,
          penetrationCustomerId,
          setPenetrationCustomerId,

          selectedReportData,
          setSelectedReportData,

          mainCategory,
          setMainCategory,
          subCategory,
          setSubCategory,
          sku,
          setSku,
          productName,
          setProductName,
          startDate,
          setStartDate,
          endDate,
          setEndDate,

          reportName,
          setReportName,
        }}
      >
        {props.children}
      </ContextProvide.Provider>
    </>
  );
};

export default ContextFunctionality;
