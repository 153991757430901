/** @format */
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../redux/actions";
import {
  HeaderRightSide,
  LastUpdatedLable,
  TextLabel,
  AvatarBtn,
  AvatarBody,
  AvatarMainWraper,
  ProfileDropdown,
  DropDownList,
  UserList,
  ListItems,
  ListData,
  LogedInUserName,
} from "./styles";
import React, { useContext, useState, useRef, useEffect } from "react";
import { ContextProvide } from "../Replenishment/ContextApi/ContextFunctionality";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../routers/Routes";

const HeaderMenu = () => {
  const { dashboardApi, stockSummeryApi } = useContext(ContextProvide);
  const { permission_list } = useSelector((state) => state?.auth);
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const ref = useRef();

  const { email, name } = useSelector((state) => state?.auth);

  //detect outside click events
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isVisible && ref.current && !ref.current.contains(e.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isVisible]);

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutAction());
  };

  const isVisibleRoute = (permission) => {
    return !permission || permission_list?.includes(permission);
  };

  return (
    <>
      <HeaderRightSide>
        {location?.pathname === "/dashboard" ||
        location?.pathname === "/stock-summery" ? (
          <LastUpdatedLable>
            Last Updated
            {dashboardApi ? (
              <TextLabel>{dashboardApi?.last_updated}</TextLabel>
            ) : stockSummeryApi ? (
              <TextLabel>{stockSummeryApi?.last_updated}</TextLabel>
            ) : (
              <LoadingOutlined />
            )}
          </LastUpdatedLable>
        ) : null}

        <AvatarMainWraper ref={ref}>
          <AvatarBtn onClick={() => setIsVisible(!isVisible)}>
            {email?.slice(0, 2).toLocaleUpperCase()}
          </AvatarBtn>

          {isVisible ? (
            <AvatarBody>
              <div>
                <LogedInUserName>{name}</LogedInUserName>
                <h4>Account Details</h4>
              </div>

              <ProfileDropdown>
                <DropDownList>
                  <UserList>
                    <ListData>
                      {routes.map((route, index) => {
                        if (route.permission) {
                          if (
                            route.permission === "user-profile" &&
                            isVisibleRoute(route.permission)
                          ) {
                            return (
                              <Link
                                to="/user-profile"
                                key={index}
                                style={{ color: "black" }}
                              >
                                User Profile
                              </Link>
                            );
                          } else if (
                            route.permission === "update-password" &&
                            isVisibleRoute(route.permission)
                          ) {
                            return (
                              <Link
                                to="/update-password"
                                key={index}
                                style={{ color: "black", paddingTop: "4px" }}
                              >
                                Update Password
                              </Link>
                            );
                          }
                        }
                      })}
                    </ListData>
                    <ListItems onClick={handleLogout}>Logout</ListItems>
                  </UserList>
                </DropDownList>
              </ProfileDropdown>
            </AvatarBody>
          ) : null}
        </AvatarMainWraper>
      </HeaderRightSide>
    </>
  );
};
export default HeaderMenu;
