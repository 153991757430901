/** @format */

import { AREAS } from "./actionTypes";

export const initialState = {
  area: null,
};

const areaReducer = (state = initialState, action) => {
  switch (action.type) {
    case AREAS:
      return {
        ...state.area,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default areaReducer;
