import styled from "styled-components";
export const MianWraper = styled.div`
  padding: 40px 0;
  background: #fff;
`;
export const FourZeroFourBg = styled.div`
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 50px;
`;

export const PageContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
