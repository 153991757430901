/** @format */

import { RSM } from "./actionTypes";

export const initialState = {
  rsm: null,
};

const rsmReducer = (state = initialState, action) => {
  switch (action.type) {
    case RSM:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default rsmReducer;
