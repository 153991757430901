/** @format */

import styled from "styled-components";

export const TableContainer = styled.div`
  overflow: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  max-width: 1440px;
  width: 100%;
  margin: auto;
  @media (max-width: 768px) {
    padding: 2rem 0;
  }
`;

export const LoadingSpinner = styled.div`
  position: absolute;
  left: 50%;
  z-index: 3;

  i {
    background-color: #1985a1;
  }
`;

export const LoadingSpin = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  top: 50%;
  z-index: 3;

  i {
    background-color: #1985a1;
  }
`;

export const progressSpinner = styled.div`
  bottom: 0;
  left: 50%;
  top: 1%;
  z-index: 3;

  i {
    background-color: #1985a1;
  }
`;

export const CriticalSpinner = styled.div`
  position: absolute;
  left: 50%;
  z-index: 3;

  i {
    background-color: #1985a1 !important;
  }
`;

export const ItemList = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 6px;

  a {
    color: black;
    &:hover {
      color: black;
    }
  }
`;

export const DropdownContainer = styled.div`
  a {
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      color: rgb(25, 133, 161);
    }
  }
`;

export const MenuWraper = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  ul {
    li {
      height: unset !important;
      line-height: unset !important;

      &:hover {
        background-color: rgb(25, 133, 161);
      }

      &:active {
        background-color: rgb(78 178 204);
      }

      a {
        &:hover {
          color: white;
        }
      }
    }
  }
`;

export const HeaderRightSide = styled.div`
  display: flex;
  gap: 18px;
  margin-right: -24px;
  /* padding-right: 62px; */
  align-items: center;
  /* Button {
   
  } */
  @media (max-width: 768px) {
    padding-right: unset;
    margin-right: 0;
  }

  Select {
    &hover {
      border-color: #e04462;
      color: black;
    }
  }
`;

export const AvatarMainWraper = styled.div`
  position: relative;
`;

export const AvatarBtn = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 50%;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #ffc107;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: #ca1033;
`;
export const AvatarBody = styled.div`
  border-radius: 4px;
  margin-top: 10px;
  padding: 12px 8px;
  right: 0;
  position: absolute;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #fff;
  border-radius: 4px;
  margin-left: -8px;
  z-index: 999;
  width: 200px;
`;

export const ProfileDropdown = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const UserList = styled.ul`
  text-decoration: none;
  padding: 0;
  margin: 0;
`;

export const ListData = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0;
`;
export const ListItems = styled.li`
  font-size: 14px;
  list-style-type: none;
  color: black;
  cursor: pointer;
  :not(:first-child) {
    padding-top: 4px;
  }

  /* &:hover {
    color: #ca1033;
  } */
`;

export const DropDownList = styled.div``;

export const Dropdown = styled.div`
  @media (max-width: 586px) {
    display: none;
  }
`;

// new changes

export const BoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
  padding: 18px 4px;
  transition: all 0.2s linear;

  /* max-height: 600px;
  height: 100%;
  overflow-y: auto; */
`;

export const TopProductsBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
  padding: 18px 4px;
  transition: all 0.2s linear;

  max-height: 550px;
  height: 100%;
  overflow: auto;
`;

export const BoxContainerSalesChart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
  padding: 18px 4px;
  transition: all 0.2s linear;
  /* max-height: 300px;
  height: 100%;
  overflow-y: auto; */
`;

export const SalesBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
  padding: 18px 4px;
  transition: all 0.2s linear;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;

export const BoxStats = styled.div``;
export const BoxTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 24px;
  color: black;
  /* color: #1985a1; */
`;
export const BoxCounter = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 12px;
`;
export const Image = styled.img`
  height: 72px;
  width: 72px;
`;

export const DonutChartContainer = styled.div`
  text {
    font-size: 12px !important;
    font-weight: 600;
  }

  .dchart-innertext-value {
    display: none;
  }
  /* .dchart-legend {
    border: 1px solid black !important;
  } */
`;

export const ChartTitle = styled.h3`
  /* margin-top: -120px; */
  text-align: center;
  font-size: 28px;
  font-weight: 500;
`;

export const AnualSalesLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

export const HeadingLable = styled.h2`
  /* padding-left: 44px; */
  font-size: 38px;
  font-weight: 600;
  /* margin-top: 50px; */
  margin: 0;
  color: black;

  @media (max-width: 1280px) {
    padding-left: 50px;
  }
  @media (max-width: 768px) {
    padding-left: 18px;
  }
  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const MainHeadingLable = styled.h2`
  /* padding-left: 44px; */
  font-size: 38px;
  font-weight: 600;
  margin-top: 50px;
  color: black;
  border-radius: 18px;
  @media (max-width: 1280px) {
    padding-left: 50px;
  }
  @media (max-width: 768px) {
    padding-left: 18px;
  }
  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const SkuLabel = styled.h2`
  padding-left: 0;
  font-size: 38px;
  font-weight: 600;
  margin-top: 18px;
  color: black;
  @media (max-width: 1280px) {
    padding-left: 50px;
  }
  @media (max-width: 768px) {
    padding-left: 18px;
  }
  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const OrdersLable = styled.div`
  font-size: 38px;
  font-weight: 600;
  color: black;
`;

export const MianLabels = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 14px;
`;

export const Name = styled.h2`
  margin: unset;
  color: black;
`;
export const Quantity = styled.h2`
  margin: unset;
  color: black;
`;

export const NameList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  /* width: 370px; */
`;

export const ProductNameList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  max-height: 550px;
`;

export const ListBullets = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 14px;

  &:not(:first-child) {
    padding-top: 4px;
  }
`;

export const ProductListBullets = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 32px;

  &:not(:first-child) {
    padding-top: 4px;
  }
`;
export const ItemsName = styled.label`
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: #ca1033;
  }
`;
export const ItemQuantity = styled.label``;
export const Divider = styled.div`
  height: 4px;
  width: 50%;
  background: #f0f0f0;
`;

export const DividerLine = styled.div`
  height: 4px;
  width: 20%;
  background: #f0f0f0;
`;

export const MoveBack = styled.img`
  padding-left: 48px;
  padding-top: 24px;
  cursor: pointer;

  @media (max-width: 1280px) {
    padding-left: 50px;
  }
  @media (max-width: 768px) {
    padding-left: 18px;
  }
`;

export const ContentWraper = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 0 1rem;
`;

export const CityWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CityLabel = styled.h2`
  position: absolute;
  top: 40%;
  font-size: 20px;
  font-weight: 500;
  color: #ca1033;

  @media (max-width: 1400px) {
    top: 38%;
  }
  @media (max-width: 1280px) {
    top: 35%;
  }
`;

export const SalesLabel = styled.h2`
  font-size: 14px;
  font-weight: 500;
  color: black;
  position: absolute;
  top: 50%;

  @media (max-width: 1280px) {
    font-size: 10px;
    top: 52%;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  max-width: 992px;
  width: 100%;
  margin: auto;
  text-align: center;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(
    0.25turn,
    rgba(56, 2, 155, 0),
    #ffc107,
    #ca1033,
    rgba(56, 2, 155, 0)
  );
  border-image-slice: 1;
  width: 100%;
`;
export const ProductContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 34px;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: start;
  }
`;
export const BoldContent = styled.h2`
  padding: unset;
  margin: unset;
  color: #ca1033;
  @media (max-width: 480px) {
    font-size: 16px !important;
  }
`;

export const ItemLabel = styled.h2`
  padding: unset;
  margin: unset;
  font-size: 16px;
  padding-left: 14px;
  @media (max-width: 992px) {
    padding-left: unset !important;
  }
  @media (max-width: 480px) {
    font-size: 12px !important;
  }
  @media (max-width: 376px) {
    font-size: 10px !important;
  }
`;

export const FeedbackImage = styled.div`
  img:hover {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

// handle next and prview
export const HandleControls = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;

  Button {
    height: unset !important;
    padding: unset !important;
    font-size: unset !important;
    border: unset !important;
    background: unset !important;
  }

  @media (max-width: 480px) {
    column-gap: 8px;
  }
`;

export const DateLabel = styled.h4`
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  width: 250px;
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  li.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-disabled {
    border-color: black !important;
  }
  button.ant-pagination-item-link,
  li.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-disabled {
    border-radius: 50% !important;
  }
  .ant-pagination-item:hover a {
    color: black !important;
  }
`;

export const CategoriesContainer = styled.div`
  padding: 0 24px;
  position: relative;
`;
export const SaleAndPurchaseDateFilter = styled.div`
  padding: 0 24px;
  position: relative;
`;
export const CatagoryDropdown = styled.div`
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 20%); */
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);; */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 14px;
  margin: 54px auto;
  width: 100%;
  display: flex;
  column-gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 758px;
  border-radius: 8px;

  @media (max-width: 768px) {
    row-gap: 24px;
  }

  @media (max-width: 1280px) {
    max-width: 650px;
  }

  @media (max-width: 992px) {
    max-width: 600px;
  }
`;

export const SegmentationFilter = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 14px;
  margin: 54px auto;
  width: 100%;
  display: flex;
  column-gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1360px;
  border-radius: 8px;
`;

export const ProductDescFilterWraper = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 14px;
  margin: 54px auto;
  width: 100%;
  display: flex;
  column-gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 800px;
  border-radius: 8px;
`;

export const ProductSummary = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 14px;
  margin: 54px auto;
  width: 100%;
  max-width: 200px;
  width: 100%;
  border-radius: 8px;
`;

export const SalesAndPurchaseContainer = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 14px;
  margin: 54px auto;
  width: 100%;
  display: flex;
  column-gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 300px;
  border-radius: 8px;
`;
export const ProductPenetrationFilter = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 14px;
  margin: 54px auto;
  width: 100%;
  display: flex;
  column-gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 500px;
  border-radius: 8px;
`;
export const CategoryWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
`;

export const LastUpdatedLable = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const TextLabel = styled.h4`
  margin: 0;
  color: black;
  font-weight: 500;
  /* border: 1px solid #de4864; */
  padding: 0 12px;
  color: black;
  border-radius: 4px;
`;

// most ranking products
export const RankingProcuts = styled.div`
  max-width: 1440px;
  width: 100%;

  max-height: 600px;
  height: 100%;

  @media (max-width: 1200px) {
    height: 600px;
  }
`;

export const ProductLabel = styled.h4`
  font-size: 22px;
  font-weight: 600;
  color: black;
`;

export const MainWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100% !important;

  @media (max-width: 1200px) {
    flex-direction: column;
    row-gap: 28px;
  }
`;

export const ProductOverViewMainWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 1200px) {
    flex-direction: column;
    row-gap: 28px;
  }
`;

export const NameAndLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 24px;

  :not(:first-child) {
    margin-top: 12px;
  }
`;

export const NameLabling = styled.div`
  font-size: 16px;
  font-weight: 600;
`;
export const SalesNameLabling = styled.div`
  font-size: 14px;
  font-weight: 600;
`;
export const UserName = styled.div`
  background-color: #facad3;
  max-width: 350px;
  text-align: center;
  width: 100%;
  padding: 2px 10px;
  border-radius: 8px;
`;

export const UserNameDropdown = styled.div`
  width: 81%;
`;
export const SegmentationNameDropdown = styled.div`
  width: 70%;
`;

export const SalesMainWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 24px;
  margin-right: -66px;

  @media (max-width: 1200px) {
    max-width: 800px !important;
    width: 100% !important;
    margin-right: unset;
  }
`;

export const OverViewMainWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 26px;
`;

export const WeeklyGrowthContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  column-gap: 26px;
  row-gap: 34px;
  @media (max-width: 1260px) {
    flex-wrap: wrap;
    margin-top: 54px;
  }
`;
export const SalesContainer = styled.div`
  max-height: 158px;
  height: 100%;
  width: 250px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 8px;
  padding: 24px 8px;
  text-align: center;
  position: relative;
`;
export const SalesTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
`;
export const SalesAmmount = styled.label`
  font-weight: 500;
  font-size: 12px;
`;

export const ProductPenetrationPname = styled.div`
  /* max-height: 116px; */
  height: 100%;
  max-width: 450px;
  width: 100%;

  @media (max-width: 1200px) {
    max-width: 800px;
    width: 100%;
    margin: auto;
  }
`;

export const PenetrationCustomerRec = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 24px 12px;
  border-radius: 8px;
`;

export const SalesWraper = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 24px 12px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
`;

export const MostProfitableSku = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 4px;
  border-radius: 8px;
  margin-top: 42px;
  height: 290px;
  position: relative;
`;

export const TopP = styled.div`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 4px;
  border-radius: 8px;
  margin-top: 42px;
  height: 290px;
  position: relative;
`;

export const MainCont = styled.div``;

export const ButtonWraper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const TopSkuTab = styled.button`
  width: 100px;
  height: 30px;
  border: none;
  font-size: 11px;
  position: absolute;
  top: 18px;
  background-color: rgb(202, 16, 51);
  border-radius: 4px;
  color: wheat;
  cursor: pointer;

  :not(:first-child) {
    margin-left: 110px;
  }
`;

export const MostProfitableSkuTab = styled.button`
  max-width: 110px;
  width: 100%;
  font-size: 11px;
  height: 30px;
  border: none;
  position: absolute;
  top: 18px;
  background-color: rgb(202, 16, 51);
  border-radius: 4px;
  color: wheat;
  cursor: pointer;

  :nth-child(2) {
    margin-left: 120px;
  }
  :nth-child(3) {
    margin-left: 240px;
  }
`;

export const LabelWithSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);; */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 14px;
  margin: 54px auto;
  border-radius: 18px;
  cursor: pointer;
`;
export const ButtonsWraper = styled.div`
  display: flex;
  column-gap: 24px;
`;

export const ProductOverReoprt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  row-gap: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const AvgSkus = styled.div`
  height: 120px;
  width: 120px;
  box-shadow: "0 3px 10px rgb(0 0 0 / 0.2)";
  margin: auto;
  background: #dc6279;
  border-radius: 8px;
  color: white;
  font-size: 34px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const AvgSkuLabel = styled.h3`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
`;

export const BarContainer = styled.div`
  height: 300px;
  padding-bottom: 18px;
`;

export const ChartLabel = styled.h3`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0;
`;

export const Dates = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 24px;
  row-gap: 24px;
`;
export const DateContainer = styled.div`
  background-color: rgba(230, 230, 230, 255);
  max-width: 500px;
  width: 100%;
  padding: 6px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #767575;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
`;
export const ProductSalesOverView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  row-gap: 24px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const FixWeekLabel = styled.div`
  background-color: #d91f3d;
  border-radius: 8px;
  padding: 4px 54px;
  color: white;
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  top: -44px;
  left: -50px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
`;

export const FixDateLabelStart = styled.div`
  background-color: white;
  border-radius: 2px;
  padding: 4px 18px;
  color: black;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: -28px;
  transform: translate(-50%, -50%);
  left: 33%;
  width: 170px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  @media (max-width: 1260px) {
    left: 38%;
  }
  @media (max-width: 992px) {
    left: 44%;
  }
  @media (max-width: 768px) {
    left: 53%;
  }
  @media (max-width: 600px) {
    top: 24px;
    left: 29%;
  }
  @media (max-width: 450px) {
    left: 25%;
    width: 132px;
    font-size: 12px;
  }
`;

export const FixDateLabelEnd = styled.div`
  background-color: white;
  border-radius: 2px;
  padding: 4px 18px;
  color: black;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: -28px;
  transform: translate(-50%, -50%);
  left: 66%;
  width: 170px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  @media (max-width: 1260px) {
    left: 72%;
  }
  @media (max-width: 992px) {
    left: 72%;
  }
  @media (max-width: 768px) {
    left: 83%;
  }
  @media (max-width: 600px) {
    top: 24px;
    left: 71%;
  }
  @media (max-width: 450px) {
    left: 74%;
    font-size: 12px;
    width: 132px;
  }
`;

export const WeeklySalesContainer = styled.div`
  max-height: 158px;
  height: 100%;
  width: 200px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 8px;
  padding: 24px 8px;
  text-align: center;
  position: relative;
`;

export const GrowthStats = styled.div`
  max-height: 158px;
  height: 100%;
  width: 200px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 8px;
  padding: 24px 8px;
  text-align: center;
  position: relative;
  background: #d91f3d;
  color: white;
`;

export const GrowthSaleTitle = styled.h3`
  font-size: 22px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
`;

export const WeekCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d91f3d;
  color: white;
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: -20px;
  box-shadow: 0 12px 30px rgb(0 0 0 / 0.2);
  @media (max-width: 1024px) {
    margin-left: 14px;
  }
`;

export const SalesGrowthTopNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 24px;
  column-gap: 24px;

  @media (max-width: 1050px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const DounetLabel = styled.h2`
  text-align: center;
  font-size: 14px;
  padding-top: 12px;
`;

export const SalesFilterWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center;
  align-items: center; */
  column-gap: 26px;
  row-gap: 24px;

  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 14px;
  margin: 54px auto;
  width: 100%;
  max-width: 1360px;
  border-radius: 8px;
`;

export const SalesUserName = styled.div`
  background-color: #facad3;
  max-width: 200px;
  text-align: center;
  width: 100%;
  padding: 2px 10px;
  border-radius: 8px;
`;

export const SaleTeam = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 580px) {
    flex-direction: column;
    row-gap: 18px;
  }
`;

export const SalesPerformanceMainWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 26px;
  row-gap: 26px;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

export const SearchContainer = styled.div`
  position: relative;
`;

export const SearchInput = styled.input`
  width: 200px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border: 1px solid #d9d9d9;
  height: 30px;
  outline: #d43f5b;
  margin-top: 1px;
`;

export const SkuFindInput = styled.input`
  max-width: 268px;
  width: 100%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border: 1px solid #d9d9d9;
  height: 30px;
  outline: #d43f5b;
  margin-top: 1px;
  border-radius: 8px;
  border: none;
  padding: 0 8px;
`;

export const SearchOptions = styled.div`
  position: absolute;
  max-width: 200px;
  width: 100%;
  background: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  border-radius: 0px;
  max-height: 250px;
  z-index: 10;
  overflow: auto;
`;

export const SearchList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 12px 4px;

  li {
    text-decoration: none;
    cursor: pointer;

    padding: 8px 2px;

    &:hover {
      background-color: lightgray;
    }
  }

  li:not(:first-child) {
    margin-top: 8px;
  }
`;

export const SelectedItems = styled.div``;
export const RegisteredUserWraper = styled.div`
  max-width: 1440px;
  width: 100%;
  padding-top: 50px;
  margin: auto;
`;

export const LogedInUserName = styled.div`
  text-align: center;
  color: #ca1033;
  padding-bottom: 8px;
`;
