/** @format */

import styled from "styled-components";
import { Link } from "react-router-dom";

export const MainContainer = styled.div``;

export const ViewBox = styled.div`
  width: 40%;
  /* border: 1px solid ${(props) => props.theme.colors.border2}; */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  right: 50%;
  @media (max-width: 768px) {
    width: 70%;
  }
  @media (max-width: 450px) {
    width: 90%;
  }
`;

export const ViewBoxHeading = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.textColor};
  height: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: 3rem;
  }
`;

export const Input = styled.input`
  outline: none;
  border: 1px solid ${(props) => props.theme.colors.border2};
  width: 100%;
  height: 2rem;
  padding: 0 10px;
`;

export const InputContainer = styled.div`
  width: 80%;
  margin: 1rem 0;
  position: relative;
`;

export const NameConatiner = styled.div``;

export const ButtonContainer = styled.div`
  width: 80%;
  margin: 0 0 0.5rem 0;
`;

export const Button = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  width: 100%;
  border: none;
  color: ${(props) => props.theme.colors.textColor};
  height: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorContainer = styled.div`
  color: red;
  width: 80%;
  margin: 0.8rem 0 0 0;
  /* height: 1.5rem; */
`;

export const OptionConatiner = styled.div`
  margin: 0 0 1rem 0;
  width: 80%;
`;

export const OptionLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const CreteUserViewBox = styled.div`
  margin: 7rem 0 2rem 0;
  width: 44%;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 70%;
  }
  @media (max-width: 450px) {
    width: 90%;
  }
`;

export const Error = styled.div`
  color: red;
  font-size: 12px;
  padding-top: 2px;
`;

export const EyeButton = styled.button`
  position: absolute;
  border: none;
  background-color: transparent;
  align-self: center;
  top: 27px;
  right: 5px;
`;

// style for user profile

export const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7d9de;
  padding: 12px;
  border-radius: 12px;
`;
export const CompanyLogo = styled.img``;
export const Body = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 38px auto;
  padding: 12px;
`;

export const EditLogo = styled.img`
  display: flex;
  margin: 28px auto 0px auto;
  border-radius: 50%;
  padding: 14px;
  cursor: pointer;
  background-color: #f7d9de;
`;
export const EditButton = styled.button`
  border: none;
  outline: none;
  background-color: unset;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  padding-top: 6px;
`;

export const UserInputContainer = styled.div`
  position: relative;
  &:not(:first-child) {
    margin-top: 28px;
  }
`;
